import { CheckIcon } from "@heroicons/react/20/solid";

const includedFeatures = [
  "Amazon Prime Integration",
  "Access to Warehouse Deals feed",
  "24/7 Support",
  "Money-back Guarantee",
];

const Plans = () => {
  return (
    <div data-aos="fade-up" id="pricing" className="py-20">
      <div className="mx-auto max-w-screen-xl px-5">
        <div className="gradient-border mx-auto w-full overflow-hidden !rounded-md p-1.5 shadow-lg lg:mx-0 lg:flex">
          <div className="gradient-border mx-auto rounded rounded-3xl bg-white lg:flex lg:max-w-none">
            <div className="p-8 sm:p-10 lg:flex-auto">
              <h3 className="inline-block text-4xl font-light tracking-tight">
                Subscribe to{" "}
                <span className="text-gradient font-semibold">FreebieFlow</span>{" "}
              </h3>
              <p className="mt-6 text-base leading-7 text-gray-600">
                Our easy-to-use AI software scans millions of products for
                freebies and massive discounts, delivering them to your door
                with free shipping.
              </p>
              <div className="mt-10 flex items-center gap-x-4">
                <h4 className="flex-none text-sm font-semibold leading-6 text-highlight">
                  What’s included
                </h4>
                <div className="h-px flex-auto bg-gray-100" />
              </div>
              <ul className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
                {includedFeatures.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon
                      className="h-6 w-5 flex-none text-highlight"
                      aria-hidden="true"
                    />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
            <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
              <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                <div className="mx-auto max-w-xs px-8">
                  <p className="text-gradient text-base font-semibold uppercase">
                    Use code HALLOWEEN for $10 off!
                  </p>

                  <p className="mt-6 flex items-baseline justify-center gap-x-2">
                    <div className="flex gap-2.5">
                      <span className="text-2xl tracking-tight text-gray-900 line-through opacity-60">
                        $49
                      </span>
                      <span className="text-5xl font-bold tracking-tight text-gray-900">
                        $39
                      </span>
                    </div>

                    <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">
                      /mo
                    </span>
                  </p>
                  <a
                    href="/dashboard/register"
                    className="button-gradient mt-6"
                  >
                    Subscribe now
                  </a>
                  <p className="mt-6 text-xs leading-5 text-gray-600">
                    Easily cancel at any time, no questions asked.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plans;
