import { Link } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";

const Hero = () => {
  return (
    <section id="hero" className="relative overflow-hidden p-5">
      <div className="blur-2xl">
        <Blobs />
      </div>
      <div className="mx-auto max-w-screen-xl">
        <div className="relative mx-auto flex w-full max-w-screen-sm flex-col items-center justify-center gap-10 lg:h-[65vh] lg:min-h-[500px] lg:max-w-none lg:flex-row lg:justify-between lg:gap-20">
          <div className="p-10 lg:p-0">
            <img
              alt="FreebieFlow Customer Dashboard"
              src="/dashboard.png"
              className="rounded-md shadow-md"
            />
          </div>
          <div className="z-10 flex w-full shrink-0 flex-col items-center gap-16 lg:w-1/2 lg:flex-row lg:items-start">
            <div className="mx-auto mb-10 h-full w-full lg:mx-0 lg:mb-0">
              <h1 className="mb-5 -mt-3 w-full text-center text-4xl font-medium tracking-tight lg:text-left lg:text-5xl lg:leading-tight">
                Get <span className="text-gradient">free stuff delivered</span>
                <br /> right to your door.
              </h1>
              <p className="mb-7 text-center text-base opacity-95 lg:text-left">
                FreebieFlow is an AI that finds{" "}
                <span className="font-semibold">free Amazon products</span> and
                orders them for you. So far, we've discovered{" "}
                <span className="font-semibold">over $84,000,000 worth</span> of
                free stuff for our users.
              </p>
              <div className="flex justify-center gap-2.5 lg:flex-row lg:justify-start">
                <Link
                  to="/dashboard/register"
                  className="button-gradient inline-flex justify-center"
                >
                  Get Started <FaChevronRight className="text-xs" />
                </Link>
                <button
                  onClick={() => {
                    const section = document.getElementById("how-it-works");
                    section.scrollIntoView({ behavior: "smooth" });
                  }}
                  className="inline-flex items-center gap-1.5 rounded-lg bg-white px-4 py-3 font-medium leading-none tracking-wide shadow transition-all lg:text-sm"
                >
                  Learn more
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;

const Blobs = () => {
  return (
    <>
      <div className="absolute top-40 w-[400px] -rotate-90 opacity-75 lg:w-[500px]">
        <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
          <path
            fill="#fce7f3"
            d="M29.1,-52C35.4,-41.1,36.7,-29.3,41.9,-18.9C47.1,-8.5,56.1,0.4,59.1,11.4C62,22.4,59,35.4,51,43.8C43.1,52.2,30.3,56,17.9,58.7C5.4,61.4,-6.8,62.9,-21.3,63C-35.8,63.1,-52.7,61.7,-61.8,52.7C-70.9,43.7,-72.3,27.2,-72.4,11.7C-72.6,-3.7,-71.6,-18,-63.9,-26.9C-56.2,-35.7,-41.8,-39.2,-30.1,-47.6C-18.3,-56,-9.1,-69.2,1.1,-71C11.4,-72.7,22.7,-62.9,29.1,-52Z"
            transform="translate(100 100)"
          />
        </svg>
      </div>
      <div className="absolute -top-52 -right-60 w-[400px] opacity-75 lg:w-[1200px]">
        <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
          <path
            fill="#fce7f3"
            d="M45.7,-75.4C55.9,-64.6,58.5,-46.2,64.8,-30.2C71,-14.2,81.1,-0.6,79.3,11.3C77.5,23.3,63.9,33.4,51.1,39.3C38.3,45.3,26.3,47,15.1,49.1C4,51.3,-6.3,53.8,-20.3,55.8C-34.2,57.7,-51.9,59,-64.7,51.8C-77.4,44.7,-85.3,29,-79.5,16.3C-73.7,3.6,-54.3,-6.2,-42.7,-14.5C-31.1,-22.9,-27.4,-29.9,-21.6,-42.7C-15.8,-55.5,-7.9,-74.2,4.9,-81.9C17.8,-89.5,35.5,-86.3,45.7,-75.4Z"
            transform="translate(100 100)"
          />
        </svg>
      </div>
    </>
  );
};
