import he from "he";
import dayjs from "dayjs";
import { FaChevronRight } from "react-icons/fa";
import { Link } from "react-router-dom";

import relativeTime from "dayjs/plugin/relativeTime";
import useAxios from "axios-hooks";
dayjs.extend(relativeTime);

const SuccessProofs = () => {
  const [{ data: checkoutList = [] }] = useAxios("/analytics/latest");

  return (
    <div className="bd-gradient py-20 px-5 lg:px-10">
      <div className="container">
        <div className="text-center">
          <div
            data-aos="fade-up"
            className="mx-auto mb-5 inline-flex justify-center rounded-full bg-white/50 px-5 py-3.5 text-center shadow-md"
          >
            <h3 className="text-gradient mx-auto text-center text-base">
              Live Feed
            </h3>
          </div>
          <h2
            data-aos="fade-up"
            className="text-gradient mb-12 pb-1.5 text-center"
          >
            What Freebies Can I Get?
          </h2>
        </div>
        <div className="grid grid-cols-2 gap-5 md:grid-cols-2 xl:grid-cols-3">
          {checkoutList
            .map((item, index) => {
              return (
                <div
                  key={index}
                  data-aos="fade-up"
                  className="flex select-none flex-col items-center gap-5 rounded-md bg-white p-2.5 text-sm shadow-md hover:shadow-lg md:flex-row lg:p-5"
                >
                  <div className="flex h-24 w-24 items-center justify-center rounded-md p-2.5">
                    <img
                      src={item.image}
                      alt=""
                      className="h-full w-full object-contain"
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <h4 className="text-neutral-800 text-center text-sm font-semibold line-clamp-2 md:text-left lg:text-lg">
                      {he.decode(item.title)}
                    </h4>
                    <p className="text-gradient text-center text-sm font-medium md:text-left">
                      ${item.price} value
                    </p>
                    <p className="text-center text-xs md:text-left">
                      {dayjs(item.time).fromNow(true)} ago
                    </p>
                  </div>
                </div>
              );
            })
            .slice(0, window.innerWidth >= 1024 ? 12 : 6)}
        </div>
        <div data-aos="fade-up" className="flex justify-center">
          <Link
            to="/dashboard/register"
            className="button-gradient mx-auto mt-16 !rounded-full"
          >
            Start getting freebies now! <FaChevronRight className="text-xs" />
          </Link>
        </div>
        <p class="mt-2.5 w-full text-center text-xs leading-5 text-gray-600">
          Easily cancel at any time, no questions asked.
        </p>
      </div>
    </div>
  );
};

export default SuccessProofs;
