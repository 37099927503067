import { FaStar } from "react-icons/fa";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import reviewData from "../../context/reviewData";
import { CheckIcon } from "@heroicons/react/20/solid";
import { checkout } from "../../utils/checkout";


const includedFeatures = [
  "Amazon Prime Integration",
  "Access to Warehouse Deals feed",
  "24/7 Support",
  "Money-back Guarantee",
];

const Plans = () => {
  return (
    <>
      <div className="container flex min-h-screen flex-col items-center gap-10 overflow-hidden py-[88px] px-5">
        <div className="mb-3 lg:text-center">
          <p
            data-aos="fade-up"
            className="mt-2 text-3xl font-semibold leading-none tracking-tight text-gray-900 sm:text-5xl"
          >
            You have <span className="text-gradient">Freebies</span> waiting for
            you!
          </p>
          <p
            data-aos="fade-up"
            className="max-w-2xl mt-4 text-gray-800 lg:mx-auto"
          >
            Subscribe now to start getting{" "}
            <b>free stuff delivered right to your door</b>. You can upgrade or
            downgrade at any time.
          </p>
        </div>
        <div data-aos="fade-up" id="pricing" className="py-10">
          <div className="max-w-screen-xl px-5 mx-auto">
            <div className="gradient-border mx-auto w-full overflow-hidden !rounded-md p-1.5 shadow-lg lg:mx-0 lg:flex">
              <div className="mx-auto bg-white rounded gradient-border rounded-3xl lg:flex lg:max-w-none">
                <div className="p-8 sm:p-10 lg:flex-auto">
                  <h3 className="inline-block text-4xl font-light tracking-tight">
                    Subscribe to{" "}
                    <span className="font-semibold text-gradient">
                      FreebieFlow
                    </span>{" "}
                  </h3>
                  <p className="mt-6 text-base leading-7 text-gray-600">
                    Our easy-to-use AI software scans millions of products for
                    freebies and massive discounts, delivering them to your door
                    with free shipping.
                  </p>
                  <div className="flex items-center mt-10 gap-x-4">
                    <h4 className="flex-none text-sm font-semibold leading-6 text-highlight">
                      What’s included
                    </h4>
                    <div className="flex-auto h-px bg-gray-100" />
                  </div>
                  <ul className="grid grid-cols-1 gap-4 mt-8 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
                    {includedFeatures.map((feature) => (
                      <li key={feature} className="flex gap-x-3">
                        <CheckIcon
                          className="flex-none w-5 h-6 text-highlight"
                          aria-hidden="true"
                        />
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="p-2 -mt-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
                  <div className="py-10 text-center rounded-2xl bg-gray-50 ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                    <button className="max-w-xs px-8 mx-auto">
                      <p className="text-base font-semibold uppercase text-gradient">
                        Use code HALLOWEEN at checkout for $10 off!
                      </p>

                      <p className="flex items-baseline justify-center mt-6 gap-x-2">
                        <div className="flex gap-2.5">
                          <span className="text-2xl tracking-tight text-gray-900 line-through opacity-60">
                            $49
                          </span>
                          <span className="text-5xl font-bold tracking-tight text-gray-900">
                            $39
                          </span>
                        </div>

                        <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">
                          /mo
                        </span>
                      </p>
                      <button
                        onClick={() => {
                          console.log("CHECKING OUT!")
                          window.gtag('event', 'conversion', {
                            'send_to': 'AW-11326216684/rtQ2CMvKod0YEOyz4Zgq',
                            'transaction_id': ''
                          });
                          checkout("price_1NELSpLVMiFmGBXg7xkIg6y9")
                        }}
                        className="mt-6 button-gradient"
                      >
                        Subscribe now
                      </button>
                      <p className="mt-6 text-xs leading-5 text-gray-600">
                        Easily cancel at any time, no questions asked.
                      </p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full">
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
          >
            <Masonry gutter={"1rem"}>
              {reviewData.map((review, index) => {
                return (
                  <article className="p-5 bg-white border rounded-lg">
                    <div className="flex gap-5">
                      <img
                        className="flex w-20 h-20 rounded-full shrink-0"
                        src={review.image}
                        alt={review.name}
                      />

                      <div>
                        <div className="">
                          <div className="mb-1.5 flex gap-0.5 text-amber-500">
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                          </div>
                          <div className="mb-1">{review.body}</div>
                          <p className="text-sm font-semibold">{review.name}</p>
                        </div>
                      </div>
                    </div>
                  </article>
                );
              })}
            </Masonry>
          </ResponsiveMasonry>
        </div>
      </div>
    </>
  );
};

export default Plans;
