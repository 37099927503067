import clsx from "clsx";
import {
  FaCog,
  FaHome,
  FaTelegramPlane,
  FaTimes,
  FaWarehouse,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import { useLocation } from "react-router-dom";
import { useDataContext } from "../../context/UserContext";
import toast from "react-hot-toast";
import UglyBanner from "./UglyBanner";
import { IoSparklesSharp } from "react-icons/io5";
import { useState } from "react";

const Nav = () => {
  const location = useLocation();
  const { user } = useDataContext();
  const telegramLink =
    "https://t.me/FreebieFlowBot/?start=" + btoa(user?.username);

  const [showBar, setShowBar] = useState(true);

  return (
    <nav
      className={clsx(
        location.pathname === "/dashboard/signin" ||
          location.pathname === "/dashboard/register"
          ? "hidden"
          : ""
      )}
    >
      <div className="gradient fixed z-[600] h-1 w-full"></div>
      <div className="fixed top-0 z-[10] flex h-[88px] w-full items-center border-b bg-white p-5 text-sm transition">
        <div className="relative mx-auto flex w-full items-center justify-between gap-2.5">
          <Link
            to="/home"
            className="flex items-center gap-1.5 text-xl font-medium lg:w-1/3"
          >
            <img src={logo} alt="" className="w-48" />
          </Link>
          <div className="lg:-1/3 hidden shrink-0 items-center justify-center gap-5"></div>
          <div className="flex w-full items-center justify-end gap-2.5 text-gray-800">
            <Link
              to="/dashboard"
              className="flex items-center gap-1.5 rounded-md px-4 py-3 font-medium leading-none transition-all hover:text-highlight lg:text-sm"
              onClick={() => {
                if (
                  user.maxAccounts === 0 &&
                  location.pathname === "/dashboard"
                ) {
                  toast.error("You need a subscription to access dashboard.");
                }
              }}
            >
              <FaHome className="text-base" />{" "}
              <span className="hidden lg:block">Dashboard</span>
            </Link>
            {user?.hasWarehouse ? (
              <Link
                to="/dashboard/warehouse"
                className="flex items-center gap-1.5 rounded-md py-3 pr-4 font-medium leading-none transition-all hover:text-highlight lg:text-sm"
              >
                <FaWarehouse className="text-base" />{" "}
                <span className="hidden lg:block">Warehouse</span>
              </Link>
            ) : null}
            <Link
              to="/dashboard/settings"
              className="flex items-center gap-1.5 rounded-md py-3 pr-4 font-medium leading-none transition-all hover:text-highlight lg:text-sm"
            >
              <FaCog className="text-base" />{" "}
              <span className="hidden lg:block">Settings</span>
            </Link>
            <a href={telegramLink} target="_blank" rel="noopener noreferrer">
              <button className="button-gradient button-gradient flex items-center rounded-md p-2.5 font-medium leading-none text-button-text transition-all sm:gap-1.5 sm:py-3 lg:px-4 lg:text-sm">
                <FaTelegramPlane className="text-base" />
                <span className="hidden sm:block">Join us on Telegram!</span>
              </button>
            </a>
          </div>
        </div>
      </div>
      <div className="h-[88px]"></div>
      <UglyBanner />

      {location.pathname === "/dashboard" &&
      showBar === true &&
      user.verifiedEmail === true ? (
        <div className="relative flex w-full items-center">
          <Link
            to="/dashboard/settings"
            className="relative flex !w-full items-center justify-center gap-2.5 !rounded-none border-b !py-2.5 px-5 text-sm font-semibold uppercase tracking-wide  !shadow-none"
          >
            <div className="text-gradient flex items-center gap-2.5">
              <IoSparklesSharp className="text-[#F25E70]" />
              Refer a friend - get $10!
              <IoSparklesSharp className="text-[#825CD0]" />
            </div>
          </Link>
          <button
            onClick={() => setShowBar(false)}
            className="absolute right-5 opacity-50 transition hover:rotate-90 hover:opacity-100"
          >
            <FaTimes />
          </button>
        </div>
      ) : null}
    </nav>
  );
};

export default Nav;
