import dayjs from "dayjs";
import CountUp from "react-countup";
import { FaChevronRight } from "react-icons/fa";
import "react-lazy-load-image-component/src/effects/blur.css";

var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const LatestDeals = () => {
  return (
    <div
      id="latest-deals"
      className="wrapper relative mx-auto !max-w-screen-2xl overflow-hidden"
    >
      <div className="container">
        <div className="flex flex-col gap-20 lg:flex-row-reverse">
          <div className="lg:w-1/2">
            <div className="mb-auto grid w-full justify-start gap-10 sm:grid-cols-2">
              <div>
                <h2 className="mb-1.5">
                  <CountUp
                    useEasing={true}
                    end={84}
                    redraw={false}
                    smartEasingThreshold={1000}
                    separator={","}
                    prefix="$"
                    suffix="m"
                    duration={"5"}
                    enableScrollSpy={true}
                    className="text-gradient inline-block font-medium"
                  />
                </h2>
                <p className="tracking-tight opacity-80">
                  worth of products discovered
                </p>
              </div>
              <div>
                <h2 className="mb-1.5">
                  <CountUp
                    useEasing={true}
                    end={2}
                    smartEasingAmount={1000000}
                    redraw={false}
                    smartEasingThreshold={1000}
                    separator={","}
                    suffix="m+"
                    duration={"4"}
                    enableScrollSpy={true}
                    className="text-gradient inline-block font-medium"
                  />
                </h2>
                <p className="tracking-tight opacity-80">
                  free products delivered
                </p>
              </div>
              <div>
                <h2 className="mb-1.5">
                  <CountUp
                    useEasing={true}
                    end={300}
                    smartEasingAmount={1000000}
                    redraw={false}
                    smartEasingThreshold={1000}
                    separator={","}
                    suffix="+"
                    prefix="$"
                    duration={"4"}
                    enableScrollSpy={true}
                    className="text-gradient inline-block font-medium"
                  />
                </h2>
                <p className="tracking-tight opacity-80">
                  saved per user per month (avg)
                </p>
              </div>
              <div>
                <h2 className="mb-1.5">
                  <CountUp
                    useEasing={true}
                    end={100}
                    smartEasingAmount={1000000}
                    redraw={false}
                    smartEasingThreshold={1000}
                    separator={","}
                    suffix="%"
                    duration={"6"}
                    enableScrollSpy={true}
                    className="text-gradient inline-block font-medium"
                  />
                </h2>
                <p className="tracking-tight opacity-80">
                  satisfaction guarantee
                </p>
              </div>
            </div>
          </div>
          <div className="lg:w-1/2">
            <h2>Our Story</h2>
            <p className="text-gray-600">
              We started out finding deals and freebies manually on Amazon.
              Constantly looking for coupons that stacked was a lot of work, but
              we persisted. About a year ago, we decided to leverage the power
              of AI to find these items faster. Fast forward to 2023 and we've
              now saved our customers millions of dollars. The best part? We're
              just getting started.
            </p>
            <button
              onClick={() => {
                scrollTo("pricing");
              }}
              className="button-gradient !rounded-full mt-7"
            >
              Let the freebies flow <FaChevronRight className="text-sm" />
            </button>
            <p class="mt-2.5 text-xs leading-5 text-gray-600 w-full">Easily cancel at any time, no questions asked.</p>
          </div>
        </div>
      </div>
    </div>
  );
};
const scrollTo = (id) => {
  const section = document.getElementById(id);
  const yOffset = -112; // account for fixed navbar
  const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
  window.scrollTo({ top: y, behavior: "smooth" });
};
export default LatestDeals;
